import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/layout/layout';
import { H1, P } from '../../components';

export default function About() {
  return (
    <Layout title="Unsubscribed from Newsletter" noIndex>
      <div className="w-full px-10 pt-32 md:pt-40 lg:pt-60">
        <H1>Newsletter Subscription</H1>
        <P>You successfully unsubscribed from the newsletter!</P>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
